import React, { useEffect } from "react";
import SubscriptionCard from "../components/SubscriptionCard";
import { useSelector } from "react-redux";

const SubscriptionPlans = () => {
  const {  planSelected } = useSelector((state) => state.auth);

  useEffect(() => {
    if(planSelected) {
      window.location.href = "/dashboard";
    }
  }, [planSelected]);
  

  const plansData = [
    // {
    //   stripeId:"0",
    //   name: "Free",
    //   price: 0,
    //   type: "",
    //   points: [
    //     "Supported with Ads",
    //     "Limited Outputs",
    //     "GPT3.5 Access",
    //     "Standard Output",
    //     "Upgrade Anytime",
    //   ],
    // },
    {
      stripeId: process.env.REACT_APP_YEAR_PRICE_ID,
      name: "Pro Yearly",
      price: 8.24,
      type: "year",
      points: [
        "2 Months Free!",
        "Text-to-Image with DALL-E",
        "Ad Free",
        "Unlimited Outputs",
        "GPT3.5 Turbo Access",
        "GPT4 128k Turbo Access",
        "Professional Prompt Library",
        "Save & Search Chats",
        "Save & Search Prompts",
        "Personal Support Concierge",
      ],
    },
    {
      stripeId: process.env.REACT_APP_MONTH_PRICE_ID,
      name: "Pro Monthly",
      price: 9.97,
      type: "month",
      points: [
        "Ad Free",
        "Text-to-Image with DALL-E",
        "Unlimited Outputs",
        "GPT3.5 Turbo Access",
        "GPT4 128k Turbo Access",
        "Professional Prompt Library",
        "Save & Search Chats",
        "Save & Search Prompts",
        "Personal Support Concierge",
      ],
    },
  ];




  return (
    <div
    className="flex flex-col justify-center items-center bg-center w-full"
    style={{
      background: "#1D232A",
      minHeight: "100vh",
     
    }}
  >
      <h1 className="text-3xl font-regular text-white mt-10 mb-5">
        Chad<span className="font-extrabold text-primary">GPT</span>
      </h1>
      <div className="flex flex-col gap-6 text-center">
        <h2 className="text-white text-4xl md:text-5xl font-medium">Select Plan</h2>
        <p className="text-lg">Please select a plan to Continue</p>
      </div>
      <div className="flex justify-center flex-wrap gap-14 my-10">
        {plansData.map((plan, index) => (
          <SubscriptionCard
            key={index}
            name={plan.name}
            price={plan.price}
            type={plan.type}
            points={plan.points}
            stripeId={plan.stripeId}
            

          />
        ))}
      </div>
    </div>
  );
};

export default SubscriptionPlans;
