import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Paperclip } from 'lucide-react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { useDispatch } from 'react-redux';

import { useAppContext } from "../AppContext";
import { updateFileLoading, updateFiles } from '../features/chats/chatSlice';
import { useSelector } from 'react-redux';

const Upload = () => {
  const { gptModel, currentChat } = useAppContext();
  const dispatch = useDispatch();
  const { loginInfo } = useSelector((state) => state.auth);

  const isVision = gptModel === 'vision';
  const isGpt4o = false;

  const [tooManyFilesErrorShown, setTooManyFilesErrorShown] = useState(false);

  const getAcceptedFileTypes = () => {
    if (isVision) {
      return {
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/webp': ['.webp'],
      };
    } else if (isGpt4o) {
      return {
        'text/x-c': ['.c'],
        'text/x-csharp': ['.cs'],
        'text/x-c++': ['.cpp'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'text/html': ['.html'],
        'text/x-java': ['.java'],
        'application/json': ['.json'],
        'text/markdown': ['.md'],
        'application/pdf': ['.pdf'],
        'text/x-php': ['.php'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'text/x-python': ['.py'],
        'text/x-script.python': ['.py'],
        'text/x-ruby': ['.rb'],
        'text/x-tex': ['.tex'],
        'text/plain': ['.txt'],
        'text/css': ['.css'],
        'text/javascript': ['.js'],
        'application/x-sh': ['.sh'],
        'application/typescript': ['.ts'],
      };
    }
    return {};
  };

  const maxFiles = 5;
  const maxSize = 5 * 1024 * 1024; // 5MB

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: getAcceptedFileTypes(),
    maxFiles,
    maxSize,
    onDrop: (acceptedFiles) => {
      dispatch(updateFileLoading(true));
      uploadFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    fileRejections.forEach(({ file, errors }) => {
      errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          toast.error(`${file.name} is too large. Max size is 5MB.`);
        } else if (err.code === 'too-many-files') {
          if (!tooManyFilesErrorShown) {
            toast.error(`Too many files. Max ${maxFiles} files are allowed.`);
            setTooManyFilesErrorShown(true);
          }
        } else if (err.code === 'file-invalid-type') {
          toast.error(`${file.name} has an invalid file type.`);
        }
      });
    });
  }, [fileRejections, tooManyFilesErrorShown]);

  const uploadFiles = async (files) => {
    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/chatbot/upload-file/${currentChat}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${loginInfo?.token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      dispatch(updateFiles(result.files));
    } catch (error) {
      toast.error(`File upload failed: ${error.message}`);
    }
  };

  if (!isVision && !isGpt4o) {
    return null;
  }

  return (
<div className='absolute left-1 top-2 cursor-pointer'>
        <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <Paperclip />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Upload;
