import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector, useDispatch } from "react-redux";
import { Copy, X } from "lucide-react";
import { useAppContext } from "../../AppContext";

import {
  createNewPrompt,
  editUserPrompt,
} from "../../features/prompts/promptSlice";
import { createNewChat } from "../../features/chats/chatSlice";
import axios from "axios";

export default function ShareConversationModal({
  type,
  open,
  setOpen,
  structureId,
}) {
  const dispatch = useDispatch();
  const { ultimatePrompt, requiredPrompt, isSuccess } = useSelector(
    (state) => state.prompt
  );

  const { loginInfo } = useSelector((state) => state.auth);

  const { updateInputValue } = useAppContext();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const [editPrompt, setEditPrompt] = useState(false);

  const [copySuccess, setCopySuccess] = useState("gg");
  const [link, setlink] = useState(
    "https://app.chadgpt.com/shareconversation/" + structureId
  );

  const [shared, setshared] = useState(true);

  // Get User chat history
  const fetchSharedInfo = async () => {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/chatbot/checksharedhistorybyid`,
      { id: structureId },
      {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      }
    );
    console.log("Hurrah", response.data);
    if (response.data.result) {
      setshared(response.data.result.isShared);
    }
  };

  useEffect(() => {
    fetchSharedInfo();
  }, []);


  
  // useEffect(() => {
  //   if (type !== "NewPrompt") {
  //     if (isSuccess || ultimatePrompt || requiredPrompt) {
  //       const prompt = ultimatePrompt ? ultimatePrompt : requiredPrompt;
  //       setFormData({
  //         name: prompt?.name,
  //         description: prompt?.description,
  //         content: prompt?.content,
  //       });
  //     }
  //   }
  // }, [isSuccess, ultimatePrompt, requiredPrompt]);

  const copyToClipboard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000); // Remove the copied message after 2 seconds
    } catch (err) {
      setCopySuccess("Failed to copy");
    }
  };
  const ChangeShared = async (data) => {
    console.log("Chaning data")
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/chatbot/changesharedhistorybyid`,
      { id: structureId, isShared:data },
      {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      }
    );
    console.log("Hurrah1", response.data);
    if (response.data.result) {
      setshared(response.data.result.isShared);
    }
  }
  const handleToggle = () => {
    console.log(shared)
    setshared(!shared);
    ChangeShared(!shared)
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="absolute z-50 inset-0 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="flex items-center justify-center min-h-screen p-4">
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* Modal */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-componentBg rounded-lg overflow-hidden mx-auto z-50">
                <div className="flex flex-row justify-end text-textColor me-4 pt-2 p-2">
                  <p
                    className="hover:cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <X
                      className="bg-dark text-white rounded-full p-1"
                      size={24}
                    />
                  </p>
                </div>
                <h1 className="text-center text-textColor text-3xl font-semibold">
                  Share Chat
                </h1>
                <div
                  //   onSubmit={handleSubmit}
                  className="text-textColor flex flex-col"
                >
                  <div className="px-[2rem] md:p-[4rem] py-[1.5rem] flex flex-col">
                    <div
                      className={`
          flex justify-between ${open ? "block" : "hidden"}`}
                    >
                      <p className="text-textColor text-lg">Share Chat</p>
                      <label className="cursor-pointer grid place-items-center mx-4">
                        <input
                          type="checkbox"
                          onChange={handleToggle}
                          value="synthwave"
                          checked={shared}
                          className="toggle theme-controller bg-base-content row-start-1 col-start-1 col-span-2 border-0"
                        />
                        {/* <!-- Tick (Check) SVG Icon --> */}
                        <svg
                          className={`col-start-2 row-start-1 stroke-base-100 fill-base-100 ${
                            !shared ? "hidden" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M5 13l4 4L19 7" />
                        </svg>
                        {/* <!-- Cross (Incorrect/Delete) SVG Icon --> */}
                        <svg
                          className={`col-start-1 row-start-1 stroke-base-100 fill-base-100 ${
                            shared ? "hidden" : ""
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M18 6L6 18M6 6l12 12" />
                        </svg>
                      </label>
                    </div>
                    {shared && (
                      <div>
                        <label className="text-xl font-semibold">
                          Share Link
                        </label>

                        <div className="flex items-center w-full border my-2 bg-componentBg focus:border-2 focus:border-borderColor focus:outline-none">
                          <input
                            className="w-full px-5 py-5 bg-componentBg text-textColor"
                            type="text"
                            name="description"
                            value={link}
                            readOnly
                          />
                          <button
                            onClick={() => copyToClipboard(link)}
                            className="p-2"
                          >
                            <Copy size={24} className="text-textColor" />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
