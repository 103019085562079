import axios from "axios";

// const API_URL = "https://app.chadgpt.com/api/chatbot/";

const API_URL = process.env.REACT_APP_API_URL + "/chatbot/";

// Get User chat history
const getUserHistory = async (token) => {
  const response = await axios.post(
    `${API_URL}gethistorytreedata`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userHistory", JSON.stringify(response.data.tree));
  }

  return response.data.tree;
};

// Create new chat
const createNewChat = async (token) => {
  const response = await axios.post(
    `${API_URL}addnewchat`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("newChat", JSON.stringify(response.data.id));
    localStorage.setItem("userHistory", JSON.stringify(response.data.result));
  }

  return response.data;
};

// Create new folder
const createNewFolder = async (token) => {
  const response = await axios.post(
    `${API_URL}addnewfolder`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("newFolder", JSON.stringify(response.data.id));
    localStorage.setItem("userHistory", JSON.stringify(response.data.result));
  }

  return response.data;
};

// Get chat history
const getChatHistory = async (id, token) => {
  const response = await axios.post(
    `${API_URL}getHistoryById`,
    { id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("chatHistory", JSON.stringify(response.data.result));
  }

  return response.data;
};

// Get shared chat history
const getSharedChatHistory = async (id) => {
  const response = await axios.get(
    `${API_URL}getsharedhistorybyid/` + id,
    
    // {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // }
  );

  if (response.data) {
    localStorage.setItem("chatHistory", JSON.stringify(response.data.result));
  }

  return response.data;
};

// Delete Structure
const deleteStructure = async (id, token) => {
  const response = await axios.delete(`${API_URL}structure/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};

// Update Structure Name
const updateStructureName = async (data) => {
  const response = await axios.put(
    `${API_URL}structure/${data.id}`,
    { title: data.title },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  return response.data;
};

// Update file parent (dragndrop)
const updateFileParent = async (data) => {
  const response = await axios.patch(
    `${API_URL}structure`,
    { folder: data.parent, file: data.chatId, tree: "history" },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userHistory", JSON.stringify(response.data.tree));
  }

  return response.data.tree;
};

// Get Ads
const getAds = async (token) => {
  const response = await axios.get(
    `https://app.chadgpt.com/api/v1/ads/getAll`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("adData", JSON.stringify(response.data));
  }

  return response.data;
};



const chatService = {
  createNewChat,
  getUserHistory,
  getChatHistory,
  createNewFolder,
  deleteStructure,
  updateStructureName,
  updateFileParent,
  getAds,
  getSharedChatHistory,
  
};

export default chatService;
