import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setOAuthLoginInfo } from '../features/auth/authSlice';

const OAuthRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate hook for navigation
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    let expiresIn = urlParams.get('expiresIn');
    let isEmailVerified = urlParams.get('isEmailVerified');
console.log("emaik", isEmailVerified)
    expiresIn = new Date(expiresIn)
    expiresIn = expiresIn.toISOString();


    if (expiresIn) {
      const loginInfo = {
        success: true,
      token:"oauth-token-here",
      expiresIn: expiresIn,
      user:{
        isEmailVerified: isEmailVerified === "true" ? true : false
      }
      }
      localStorage.setItem('expiresIn', expiresIn);
      localStorage.setItem('version', "2.1");
      localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
      dispatch(setOAuthLoginInfo(loginInfo))

      navigate('/');
    }
  }, [location, navigate]); // Update dependency array

  return (
    <div>
      Loading...
    </div>
  );
};

export default OAuthRedirect;
