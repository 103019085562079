import { Save } from 'lucide-react';
import React, { useState } from 'react';

const GPTResponseImage = ({ imageKey, imageURL }) => {
  const fallbackImage = "/path/to/fallback/image.png"; // Update with your actual fallback image path

  const [tooltipText, setTooltipText] = useState("Download");

  const handleDownload = () => {
    let downloadUrl;
  
    if (imageKey) {
      downloadUrl = `${process.env.REACT_APP_API_URL}/chatbot/download-image?imageKey=${imageKey}`;
    } else if (imageURL) {
      downloadUrl = `${process.env.REACT_APP_API_URL}/chatbot/download-image?imageURL=${encodeURIComponent(imageURL)}`;
    } else {
      console.error('No valid image source provided.');
      return;
    }
  
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
    setTooltipText("Downloaded!");
    setTimeout(() => {
      setTooltipText("Download");
    }, 2000);
  };
  

  return (
    <div className="border-2 border-borderColor rounded-lg p-4 flex flex-col w-full gap-3">
      <div className="flex items-center justify-between gap-3">
        <div className="avatar flex items-center gap-2">
          <div className="w-8 rounded-full border-2 border-borderColor bg-dark p-1">
            <img src="/assets/imgs/icon.png" alt="icon" />
          </div>
          <h1 className="text-textColor font-semibold">ChadGPT</h1>
        </div>
        <span
          id="download-btn"
          data-tip={tooltipText}
          className="cursor-pointer text-textColor tooltip"
          onClick={handleDownload}
        >
         <Save size={20} />
        </span>
      </div>

      <div className="p-1">
        <img 
          src={imageURL || `https://chadgpt-images.s3.us-east-2.amazonaws.com/${imageKey}`} 
          alt="" 
          onError={(e) => e.target.src = fallbackImage}
        />
      </div>
    </div>
  );
};

export default GPTResponseImage;
