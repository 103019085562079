import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Turnstile, { useTurnstile } from "react-turnstile";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../features/auth/authSlice";
import { getMe } from "../features/auth/authSlice";
import { getUserHistory } from "../features/chats/chatSlice";
import {
  getSystemPrompts,
  getUserPrompts,
} from "../features/prompts/promptSlice";
import axios from "axios";

const ForgetPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const turnstile = useTurnstile();
  const turnstileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY;
  const [turnstileToken, setTurnstileToken] = useState("");

  const { email } = formData;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loginInfo, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error("Invalid credentials. Please Try again!", {
        closeOnClick: true,
        autoClose: 2000,
      });
    }

    if (isSuccess || loginInfo?.token) {
      dispatch(getMe(loginInfo.token));
      dispatch(getUserHistory(loginInfo.token));
      dispatch(getSystemPrompts(loginInfo.token));
      dispatch(getUserPrompts(loginInfo.token));
      navigate("/dashboard");
    }
  }, [isError, isSuccess, loginInfo, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!turnstileToken) {
      toast.error("Please complete the Captcha", {
        closeOnClick: true,
        autoClose: 2000,
      });
      return;
    }

    const userData = {
      email,
      'cf-turnstile-response': turnstileToken,
    };
    turnstile.reset();


    try {
      setIsSubmitting(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotPassword`, userData);

      setFormData({
        email: "",
      });

      toast.success("Password Reset Link Sent, please check your email", {
        closeOnClick: true,
        autoClose: 2000,
      });

      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);

      toast.error("Unable to Reset Password", {
        closeOnClick: true,
        autoClose: 2000,
      });
    }
  };

  return (
    <section
      className="bg-dark flex flex-col justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white text-center">
          Welcome to{" "}
          <span
            className="border-s-4 border-primary"
            style={{
              background:
                "linear-gradient(90deg, rgba(32, 253, 201, 0.50) 0%, rgba(32, 253, 201, 0.00) 100%)",
            }}
          >
            ChadGPT
          </span>
        </h1>

        <div className="mt-14">
          <form
            className="flex flex-col justify-center items-center gap-5 w-full"
            onSubmit={onSubmit}
          >
            <div className="input-group">
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={onChange}
                className="px-3 py-4 w-96 focus:outline-primary"
                autoComplete="email"
                required
              />
              <label htmlFor="email">Email Address</label>
            </div>

            <Turnstile
              sitekey={turnstileSiteKey}
              onVerify={(token) => {
                setTurnstileToken(token);
              }}
            />

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-96 p-4 bg-primary text-dark rounded-md font-semibold text-lg"
            >
              {isLoading ? "Loading..." : "Reset Password"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
