import { createContext, useContext, useState } from "react";
import { updateFiles } from "./features/chats/chatSlice";
import { useDispatch } from "react-redux";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState("");
  const [gptModel, setGptModel] = useState("3.5");
  const [currentChat, setCurrentChat] = useState("");

  const dispatch = useDispatch();
  const updateInputValue = (newValue) => {
    setInputValue(newValue);
  };

  const updateGPTModel = (newVal) => {
    setGptModel(newVal);
    dispatch(updateFiles([]))
  };

  const updateCurrentChat = (newVal) => {
    setCurrentChat(newVal);
  };

  return (
    <AppContext.Provider
      value={{
        inputValue,
        updateInputValue,
        gptModel,
        updateGPTModel,
        currentChat,
        updateCurrentChat,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
