import axios from "axios";

const API_URL = "https://app.chadgpt.com/api/chatbot/";

// Get System Prompts
const getSystemPrompts = async (token) => {
  const response = await axios.post(
    `${API_URL}getsysprompttree`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("systemPrompts", JSON.stringify(response.data.result));
  }

  return response.data.result;
};

// Get System prompt by id
const getSystemPromptByID = async (id, token) => {
  const response = await axios.post(
    `${API_URL}getultimatepromptbyid`,
    { id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem(
      "ultimatePrompt",
      JSON.stringify(response.data.result)
    );
  }

  return response.data.result;
};

// Create new prompt
const createNewPrompt = async (data) => {
  const response = await axios.post(
    `${API_URL}addnewprompt`,
    { name: data.name, description: data.description, content: data.content },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userPrompts", JSON.stringify(response.data.result));
  }

  return response.data.result;
};

// Create new prompt folder
const createNewPromptFolder = async (token) => {
  const response = await axios.post(
    `${API_URL}addnewpromptfolder`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userPrompts", JSON.stringify(response.data.result));
  }

  return response.data.result;
};

// Get user Prompts
const getUserPrompts = async (token) => {
  const response = await axios.post(
    `${API_URL}getprompttreedata`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userPrompts", JSON.stringify(response.data.result));
  }

  return response.data.result;
};

// Get user prompt by id
const getUserPromptByID = async (id, token) => {
  const response = await axios.post(
    `${API_URL}getpromptbyid`,
    { id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem(
      "requiredPrompt",
      JSON.stringify(response.data.result)
    );
  }

  return response.data.result;
};

// Edit user prompt
const editUserPrompt = async (data) => {
  const response = await axios.post(
    `${API_URL}saveprompt`,
    {
      id: data.id,
      name: data.name,
      description: data.description,
      content: data.content,
    },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );

  if (response.data) {
    localStorage.setItem("userPrompts", JSON.stringify(response.data.result));
  }

  return response.data.result;
};


// Update file parent (dragndrop)
const updatePromptParent = async (data) => {
  const response = await axios.patch(
    `${API_URL}structure`,
    { folder: data.parent, file: data.promptId, tree: "prompt" },
    {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    }
  );
  
  if (response.data) {
    localStorage.setItem("userPrompts", JSON.stringify(response.data.tree));
  }

  return response.data.tree;
};

const promptService = {
  getSystemPrompts,
  getSystemPromptByID,
  createNewPrompt,
  createNewPromptFolder,
  getUserPrompts,
  getUserPromptByID,
  editUserPrompt,
  updatePromptParent
};

export default promptService;
