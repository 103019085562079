import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getMe } from "../features/auth/authSlice";
import { useAppContext } from "../AppContext";

import NewChat from "./NewChat";
import { useParams } from "react-router-dom";
import QuestionContainer from "./QuestionContainer";
import GPTResponseContainer from "./GPTResponseContainer";
import PromptInput from "./PromptInput";
import { toast } from "react-toastify";
import GPTResponseImage from "./GPTResponseImage";
import axios from "axios";
import { getSharedChatHistory } from "../features/chats/chatSlice";
import ShareQuestionContainer from "./ShareQuestionContainer";
import { PencilLine } from "lucide-react";

const ShareConversation = () => {
  let { id } = useParams();
  const dispatch = useDispatch();

  const { loginInfo } = useSelector((state) => state.auth);
  const { chatMessages } = useSelector((state) => state.chat);

  const [messages, setMessages] = useState(chatMessages || []);

  const bottomRef = useRef(null);

  useEffect(() => {
    if (chatMessages) {
      setMessages(chatMessages);
    }

    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatMessages]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleChatFetch = () => {
    const data = { id: id };
    console.log("GG");
    dispatch(getSharedChatHistory(data));
  };

  useEffect(() => {
    handleChatFetch();
  }, []);

  return (
    <div
      
      className="flex flex-col text-white overflow-auto h-full "
    >
      <div
        className={`max-w-[1300px] mx-auto pb-[10rem] flex flex-col ${
          messages.length === 0 && "h-full"
        }`}
      >
        {messages?.length !== 0 ? (
          <>
            <div className="px-5 pt-5 flex flex-col justify-start gap-6">
              {messages?.map((chat, index) => {
                // Check if the chat includes an image URL or image key
                if (chat.imageUrl || chat.imageKey) {
                  return (
                    <GPTResponseImage
                      key={index}
                      imageURL={chat.imageUrl}
                      imageKey={chat.imageKey}
                    />
                  );
                } else {
                  // Fallback to the original condition for text-based content
                  return chat.role === "user" ? (
                    <ShareQuestionContainer
                      key={index}
                      content={chat.content}
                    />
                  ) : (
                    chat.content !== "" && (
                      <>
                        <GPTResponseContainer
                          key={index}
                          content={chat.content}
                        />
                      </>
                    )
                  );
                }
              })}
            </div>
            <div ref={bottomRef} />
          </>
        ) : (
          <NewChat />
        )}
      </div>
    </div>
  );
};

export default ShareConversation;
