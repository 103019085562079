import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AppProvider } from "./AppContext";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Register from "./pages/Register";

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import OAuthRedirect from "./pages/OAuthRedirect";
import { Navigate, Outlet } from 'react-router-dom';
import SubscriptionPlans from "./pages/SubscriptionPlans";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import PageTracker from './PageTracker';
import ShareConversation from "./components/ShareConversation";
import ShareConversationPage from "./pages/ShareConversationPage";
import VerificationPageData from "./pages/VerificationPageData";
import VerificationEmailPageData from "./pages/VerificationEmailPageData";
import { useDispatch } from "react-redux";
import { logout } from "./features/auth/authSlice";




// Step 2: Protected Route Component
const ProtectedRoute = () => {
  const dispatch = useDispatch();

  const isAuthenticated = () => {
    const expiresIn = localStorage.getItem('expiresIn');
    const version = localStorage.getItem('version');
    const now = new Date();
  
    if (version !== "2.1") {
      localStorage.clear();
      return false;
    }
  
    // Since expiresIn is stored as an ISO string, directly use it to create a Date object
    if (expiresIn) {
      const expirationDate = new Date(expiresIn);
      if (now < expirationDate) {
        return true; // session is valid
      }
    }
    localStorage.clear();
    dispatch(logout());
    return false; // session is invalid or expired
  };
  const auth = isAuthenticated();

  return auth ? <Outlet /> : <Navigate to="/" />;
};

// Update the Routes in App function
function App() {

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <AppProvider>
          <BrowserRouter>
            <PageTracker />
            <Routes>
              <Route
                path="/shareconversation/:id"
                element={<ShareConversationPage />}
              />
              <Route element={<ProtectedRoute />}>
                {" "}
                {/* Wrap the Dashboard route */}
                <Route path="/dashboard" element={<Dashboard />} />
              </Route>
              <Route path="/" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route
                path="/rest-password-account/:token"
                element={<ResetPassword />}
              />

              <Route path="/email-verify" element={<VerificationPageData />} />

              <Route path="/email-verify/:id" element={<VerificationEmailPageData />} />

              <Route path="/oauth/redirect" element={<OAuthRedirect />} />
              <Route
                path="/subscription-plans"
                element={<SubscriptionPlans />}
              />
            </Routes>
          </BrowserRouter>
          <ToastContainer />
        </AppProvider>
      </DndProvider>
    </>
  );
}
export default App;
