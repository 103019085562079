import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatService from "./chatService";

// Get info from localStorage
const newChat = JSON.parse(localStorage.getItem("newChat"));
const newFolder = JSON.parse(localStorage.getItem("newFolder"));
const userHistory = JSON.parse(localStorage.getItem("userHistory"));
const adData = JSON.parse(localStorage.getItem("adData"));

const initialState = {
  newChat: newChat ? newChat : null,
  newFolder: newFolder ? newFolder : null,
  userHistory: userHistory ? userHistory : null,
  chatMessages: null,
  adData: adData ? adData : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  files: [],
  fileLoading: false,
};

// get user history
export const getUserHistory = createAsyncThunk(
  "chat/userHistory",
  async (token, thunkAPI) => {
    try {
      return await chatService.getUserHistory(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new chat
export const createNewChat = createAsyncThunk(
  "chat/newChat",
  async (token, thunkAPI) => {
    try {
      return await chatService.createNewChat(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create new folder
export const createNewFolder = createAsyncThunk(
  "chat/newFolder",
  async (token, thunkAPI) => {
    try {
      return await chatService.createNewFolder(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get chat history
export const getChatHistory = createAsyncThunk(
  "chat/chatHistory",
  async (data, thunkAPI) => {
    try {
      return await chatService.getChatHistory(data.id, data.token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get shared chat history
export const getSharedChatHistory = createAsyncThunk(
  "chat/sharedchatHistory",
  async (data, thunkAPI) => {
    try {
      return await chatService.getSharedChatHistory(data.id, data.token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete Structure
export const deleteStructure = createAsyncThunk(
  "chat/deleteStructure",
  async (data, thunkAPI) => {
    try {
      return await chatService.deleteStructure(data.id, data.token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update structure name
export const updateStructureName = createAsyncThunk(
  "chat/updateStructureName",
  async (data, thunkAPI) => {
    try {
      return await chatService.updateStructureName(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update file parent (dragndrop)
export const updateFileParent = createAsyncThunk(
  "chat/updateFileParent",
  async (data, thunkAPI) => {
    try {
      return await chatService.updateFileParent(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Get ads
export const getAds = createAsyncThunk(
  "chat/adData",
  async (token, thunkAPI) => {
    try {
      return await chatService.getAds(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);




export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    removeStructureFromUserHistory: (state, action) => {
      const deletedStructureId = action.payload;
      state.userHistory = state.userHistory.filter(
        (structure) => structure.id !== deletedStructureId
      );
      localStorage.setItem("userHistory", JSON.stringify(state.userHistory));
    },
    updateChatStructureName: (state, action) => {
      const data = action.payload;
      state.userHistory = state.userHistory.map((structure) =>
        structure._id === data.id
          ? { ...structure, text: data.name }
          : structure
      );

      localStorage.setItem("userHistory", JSON.stringify(state.userHistory));
    },
    dragFileToFolder: (state, action) => {
      const data = action.payload;
      state.userHistory = state.userHistory.map((structure) =>
        structure._id === data.chatId
          ? { ...structure, parent: data.parent }
          : structure
      );

      localStorage.setItem("userHistory", JSON.stringify(state.userHistory));
    },
    updateFiles: (state, action) => {
      state.files = action.payload;
      state.fileLoading = false;
    },
    updateFileLoading: (state, action) => {
      state.fileLoading = action.payload;
    },
    removeFile: (state, action) => {

      state.files = state.files.filter((file, index) => index !== action.payload.index);

      
    }


  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userHistory = action.payload;
      })
      .addCase(getUserHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.userHistory = null;
      })
      .addCase(createNewChat.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewChat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userHistory = action.payload.result;
        state.newChat = action.payload.id;
      })
      .addCase(createNewChat.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newChat = null;
      })
      .addCase(getChatHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChatHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.chatMessages = action.payload.result;
      })
      .addCase(getChatHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.chatMessages = null;
      })
      .addCase(getSharedChatHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSharedChatHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.chatMessages = action.payload.result;
      })
      .addCase(getSharedChatHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.chatMessages = null;
      })
      .addCase(createNewFolder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewFolder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userHistory = action.payload.result;
        state.newFolder = action.payload.id;
      })
      .addCase(createNewFolder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.newFolder = null;
      })
      .addCase(deleteStructure.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteStructure.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteStructure.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateStructureName.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStructureName.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateStructureName.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateFileParent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFileParent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.userHistory = action.payload;
      })
      .addCase(updateFileParent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.adData = action.payload;
      })
      .addCase(getAds.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  reset,
  removeStructureFromUserHistory,
  updateChatStructureName,
  dragFileToFolder,
  updateFiles,
  updateFileLoading,
  removeFile
} = chatSlice.actions;
export default chatSlice.reducer;
