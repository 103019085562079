import React, { useState } from "react";
import Markdown from "react-markdown";

const GPTResponseContainer = ({ content }) => {
  const [tooltipText, setTooltipText] = useState("Copy");
  const handleCopyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
    setTooltipText("Copied!");
    setTimeout(() => {
      setTooltipText("Copy");
    }, 2000);
  };

  const customComponents = {
    strong: ({ node, ...props }) => <strong className="text-textColor font-semibold" {...props} />,
    code: ({ node, ...props }) => <code className="text-textColor text-bold italic" {...props} />,
    h1: ({ node, ...props }) => <h1 className="text-textColor" {...props} />,
    h2: ({ node, ...props }) => <h2 className="text-textColor" {...props} />,
    h3: ({ node, ...props }) => <h3 className="text-textColor" {...props} />,
    h4: ({ node, ...props }) => <h4 className="text-textColor" {...props} />,
    h5: ({ node, ...props }) => <h5 className="text-textColor" {...props} />,
    h6: ({ node, ...props }) => <h6 className="text-textColor" {...props} />,
  };

  return (
    <div className="border-2 border-borderColor rounded-lg p-4 flex flex-col w-full gap-3">
      <div className="flex items-center justify-between gap-3">
        <div className="avatar flex items-center gap-2">
          <div className="w-8 rounded-full border-2 border-borderColor bg-dark p-1">
            <img src="/assets/imgs/icon.png" />
          </div>
          <h1 className="text-textColor font-semibold">ChadGPT</h1>
        </div>
        <span
          id="copy-btn"
          data-tip={tooltipText}
          className="cursor-pointer text-textColor tooltip"
          onClick={() => handleCopyToClipboard(content)}
        >
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 2.4V12.4C15 13.44 14.3311 14.32 13.3784 14.66C13.2365 14.72 13.0743 14.74 12.9324 14.78C12.9527 14.66 12.973 14.54 12.973 14.42V13.54C13.4595 13.36 13.7838 12.92 13.7838 12.42V2.4C13.7838 1.74 13.2365 1.2 12.5676 1.2H5.67568C5.16892 1.2 4.7027 1.52 4.52027 2H3.64865C3.52703 2 3.40541 2.02 3.28378 2.04C3.30405 1.9 3.34459 1.76 3.38514 1.62C3.70946 0.68 4.62162 0 5.67568 0H12.5676C13.9054 0 15 1.08 15 2.4ZM11.7568 5.6V15.6C11.7568 15.96 11.6757 16.28 11.5338 16.58C11.1486 17.42 10.2973 18 9.32432 18H2.43243C1.09459 18 0 16.92 0 15.6V5.6C0 4.62 0.587838 3.8 1.43919 3.42C1.74324 3.28 2.08784 3.2 2.43243 3.2H9.32432C10.6622 3.2 11.7568 4.28 11.7568 5.6ZM10.5405 5.6C10.5405 4.94 9.99324 4.4 9.32432 4.4H2.43243C1.76351 4.4 1.21622 4.94 1.21622 5.6V15.6C1.21622 16.26 1.76351 16.8 2.43243 16.8H9.32432C9.99324 16.8 10.5405 16.26 10.5405 15.6V5.6Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>

      <div className="p-1">
        <Markdown className="prose max-w-none text-textColor" components={customComponents}>
          {content}
        </Markdown>
      </div>
    </div>
  );
};

export default GPTResponseContainer;
