import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerificationPageData = () => {
  const [text, setText] = useState('A verification email has been sent to your email address. Please verify your email to activate your account and gain access.');
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTimer, setCooldownTimer] = useState(120); // 120 seconds or 2 minutes

  const { loginInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const EmailVerificationSend = async () => {
    setText('Sending Email....');
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-email`, {
        headers: {
          Authorization: `Bearer ${loginInfo.token}`,
        },
      });
      setText('Your email has been sent');
      setCooldown(true);
      let counter = cooldownTimer;
      const interval = setInterval(() => {
        setCooldownTimer(--counter);
        if (counter <= 0) {
          clearInterval(interval);
          setCooldown(false);
          setCooldownTimer(120); // Reset timer for next use
        }
      }, 1000);
    } catch (error) {
      setText('An error occurred while sending your email');
    }
  };

  useEffect(() => {
    if (!loginInfo) {
      navigate('/');
    }
  }, [loginInfo, navigate]);

  return (
    <section
      className="bg-dark flex flex-col justify-center items-center h-screen"
      style={{
        backgroundImage: 'url(/assets/imgs/authBack.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white text-center">
          Welcome to <span className="border-s-4 border-primary" style={{
            background: 'linear-gradient(90deg, rgba(32, 253, 201, 0.50) 0%, rgba(32, 253, 201, 0.00) 100%)',
          }}>ChadGPT</span>
        </h1>

        <div className="mt-5 text-2xl md:text-2xl font-semibold text-white text-center">
          <h4>{text}</h4>
        </div>
        <button
          className="mt-5 w-96 p-4 bg-primary text-dark rounded-md font-semibold text-lg"
          onClick={EmailVerificationSend}
          disabled={cooldown}
        >
          {cooldown ? `Please wait ${cooldownTimer} seconds to resend` : 'Resend Email'}
        </button>
      </div>
    </section>
  );
};

export default VerificationPageData;
