import React, { useState } from "react";
import { Save, Trash } from "lucide-react";
import { useSelector } from "react-redux";

import PromptModal from "./modals/PromptModal";
import UserProfileImage from "./UserProfileImage";
import FileUploadCard from "./FileUploadCard";

const QuestionContainer = ({ content, files }) => {
  const [save, setSave] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const renderContentWithLineBreaks = (textContent) => {
    return textContent.split("\n").map((line, index) => (
      // Render each line in a separate div, using index as key
      <div key={index} className="text-textColor font-semibold text-gl">
        {line}
      </div>
    ));
  };

  return (
    <div className="min-w-full">
      <div className="flex gap-2 justify-between">
        <div className="">
          <div className="avatar">
            <UserProfileImage user={user} />
            {/* <div className="w-8 rounded-full">
              <img
                src={
                  user?.data.photoURL
                    ? user?.data.photoURL
                    : "/assets/imgs/profile.jpg"
                }
              />
            </div> */}
          </div>
        </div>
        <div className="self-start w-full justify-self-start items-start">
          {renderContentWithLineBreaks(content)}
        </div>
        {/* <div className="self-start w-full justify-self-start items-start">
          <h1 className="text-textColor font-semibold text-gl">{content}</h1>
        </div> */}
        <div>
          <div className="flex items-center gap-2">
            <Save
              size={20}
              className="text-textColor cursor-pointer"
              onClick={() => setSave(true)}
            />
            {/* <Trash size={20} className="text-redDelete cursor-pointer" /> */}
          </div>
        </div>
      </div>
      {save && (
        <PromptModal
          type={"NewPrompt"}
          open={save}
          setOpen={setSave}
          currentPromptId={""}
          promptContent={content}
        />
      )}
      {files && files.length > 0 && (
        <div className="flex flex-wrap gap-2">
          {files.map((file, index) => {
            const fileName = file.split("/").pop();
            const fileType = fileName.split(".").pop();
            const link = `https://chadgpt-images.s3.us-east-2.amazonaws.com/${file}`;

            return (
              <FileUploadCard
                key={index}
                fileName={fileName}
                fileType={fileType}
                link={link}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default QuestionContainer;
