import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import chatReducer from "../features/chats/chatSlice";
import promptReducer from "../features/prompts/promptSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    chat: chatReducer,
    prompt: promptReducer
  },
});
