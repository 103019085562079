import React, { useRef, useEffect } from "react";
import { useAppContext } from "../AppContext";
import { useState } from "react";
import FileUploadContainer from "./FileUploadContainer";
import Upload from "./Upload";

const PromptInput = ({ handleSubmit }) => {
  const { inputValue, updateInputValue,gptModel } = useAppContext();
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = "auto";
    const numberOfLines = textarea.value.split("\n").length;
    const maxHeight = 10;

    if (numberOfLines <= maxHeight) {
      textarea.style.overflowY = "hidden";
      textarea.style.height = `${textarea.scrollHeight}px`;
    } else {
      textarea.style.overflowY = "scroll";
      textarea.style.height = `${20 * maxHeight}px`;
    }
  };

  const handleChange = (e) => {
    updateInputValue(e.target.value);
    adjustTextareaHeight();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action of the Enter key
      if (e.shiftKey) {
        // Shift+Enter, add new line
        const newValue = `${inputValue}\n`;
        updateInputValue(newValue);
        setTimeout(adjustTextareaHeight, 0);
      } else {
        // Enter alone, submit form
        handleSubmit(e); // Ensure handleSubmit can handle the event object
      }
    }
  };

  const handlePaste = () => {
    setTimeout(adjustTextareaHeight, 0);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputValue]);

  return (
    <>
      <div className="p-1">
        <FileUploadContainer />
      </div>

      <div className="flex flex-col items-center gap-4 text-textColor w-full px-4 pb-5">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
          className="w-full relative border-2 rounded-lg overflow-hidden"
        >
          <Upload />

          <textarea
  ref={textareaRef}
  className="textarea-class bg-transparent w-full focus:outline-none resize-none p-2 pl-8 "
  value={inputValue}
  onChange={handleChange}
  onKeyDown={handleKeyDown}
  onPaste={handlePaste}
  style={{ minHeight: "38px" }}
  placeholder={gptModel === 'vision' ? "Attach images (JPG or PNG) and ask me questions about them" : "Example: “Explain quantum computing in simple terms”"}
/>
          <button
            type="submit"
            className="submit-button bg-primary p-2 rounded-lg absolute right-2 bottom-2"
            style={{ height: "34px", minWidth: "38px" }}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_930_225)">
                <path
                  d="M3.16667 17.0707L17.7083 10.8374C18.3833 10.5457 18.3833 9.59573 17.7083 9.30407L3.16667 3.07073C2.61667 2.82907 2.00833 3.2374 2.00833 3.82907L2 7.67073C2 8.0874 2.30833 8.44573 2.725 8.49573L14.5 10.0707L2.725 11.6374C2.30833 11.6957 2 12.0541 2 12.4707L2.00833 16.3124C2.00833 16.9041 2.61667 17.3124 3.16667 17.0707Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_930_225">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </form>
      </div>
    </>
  );
};

export default PromptInput;
