import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "lucide-react";
import { toast } from "react-toastify";
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";


const monthly_priceId = process.env.REACT_APP_MONTH_PRICE_ID;
const yearly_priceId = process.env.REACT_APP_YEAR_PRICE_ID;

export default function PricingModal({ open, setOpen }) {

  
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [monthlyPrice, setMonthlyPrice] = useState(9.97);
  const [yearlyPrice, setYearlyPrice] = useState(98.97);
  // const stripe = useStripe();
  // const elements = useElements();
  const [loading1, setloading1] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoId, setPromoId] = useState("");

  const navigate = useNavigate();

  const setDefaultPrices = () => {
    setMonthlyPrice(9.97);
    setYearlyPrice(98.97);
  };

  function get_ga_clientid() {
    var cookie = {};
    document.cookie.split(';').forEach(function(el) {
      var splitCookie = el.split('=');
      var key = splitCookie[0].trim();
      var value = splitCookie[1];
      cookie[key] = value;
    });
  return cookie["_ga"].substring(6);
  }

  console.log(get_ga_clientid());

  // const createSubscription = async (type) => {
  //   let priceId = ''
  //   if (type === 'month') {
  //     priceId = monthly_priceId
  //     setloading1(true)
  //   } else {
  //     priceId = yearly_priceId
  //     setloading2(true)
  //   }

  //   try {
  //     if (!elements || !elements.getElement(CardElement)) {
  //       console.error('CardElement is not available.')
  //       return
  //     }
  //     ReactGA.event({
  //       category: "checkout",
  //       action: "checkout_initiated",       
      
  //     });
      
  //     const paymentMethod = await stripe.createPaymentMethod({
  //       type: 'card',
  //       card: elements.getElement(CardElement),
        
  //     })

  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/stripe/create-subscription`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           paymentMethod: paymentMethod.paymentMethod.id,
  //           name,
  //           email,
  //           priceId,
  //           promoId: promoId,
  //         }),
  //       },
  //     ).then((res) => res.json())

  //     if (response.success === false) {
  //       toast.error(response.error, {
  //         hideProgressBar: true,
  //         draggable: true,
  //       })

  //       setloading1(false)
  //       setloading2(false)
  //       return
  //     }

  //     const confirmPayment = await stripe.confirmCardPayment(
  //       response.clientSecret,
  //     )

  //     // console.log(confirmPayment)

  //     if (confirmPayment.error) {
  //       await axios.post(
  //         `${process.env.REACT_APP_API_URL}/stripe/update-payment-status`,
  //         {
  //           subscriptionId: response.subscriptionId,
  //         },
  //       )
  //       ReactGA.event({
  //         category: "checkout",
  //         action: "checkout_failed",       
        
  //       });
  //       toast.success(confirmPayment.error.message, {
  //         hideProgressBar: true,
  //         draggable: true,
  //       })
  //     } else {
  //       await axios.post(
  //         `${process.env.REACT_APP_API_URL}/stripe/update-payment-status`,
  //         {
  //           subscriptionId: response.subscriptionId,
  //         },
  //       )

  //       setOpen(false)
        

  //       toast.success('Pro Version Activated!', {
  //         hideProgressBar: true,
  //         draggable: true,
  //       })

  //       navigate(0);
  //       ReactGA.event({
  //         category: "checkout",
  //         action: "checkout_success",       
        
  //       });
  //     }
  //   } catch (error) {
  //     toast.error("Please check you Card Details, Error Processing it", {
  //       hideProgressBar: true,
  //       draggable: true,
  //     })

  //   }
  //   setloading1(false)
  //   setloading2(false)
  // }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };


  const NewcreateSubscription = async (type) => {
    let priceId = ''
    if (type === 'month') {
      priceId = monthly_priceId
      setloading1(true)
    } else {
      priceId = yearly_priceId
      setloading2(true)
    }

    try {

      let data = await axios.get(`${process.env.REACT_APP_API_URL}/stripe/checkout/${priceId}`)

      if (data.data.session.url) {

        ReactGA.event({
          category: "checkout",
          action: "checkout_initiated",       
        
        });
        
        
        window
          .open(data.data.session.url, "_blank")
          .focus();
      } else {
        toast.error("Failed to initiate subscription. Please try again.", {
          hideProgressBar: true,
          draggable: true,
        })
      }


     
    } catch (error) {
      toast.error("Please check you Card Details, Error Processing it", {
        hideProgressBar: true,
        draggable: true,
      })

    }
    setloading1(false)
    setloading2(false)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="absolute z-50 inset-0 overflow-y-auto"
          onClose={() => {}}
        >
          <div className="flex items-center justify-center min-h-screen p-4">
            {/* Overlay */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* Modal */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative bg-pageBg rounded-lg overflow-hidden mx-auto overflow-y-scroll no-scrollbar z-100">
                <div className="flex flex-row justify-end text-textColor me-4 pt-4 p-2">
                  <p
                    className="hover:cursor-pointer"
                    onClick={() => setOpen(false)}
                  >
                    <X
                      className="bg-dark text-white rounded-full p-1"
                      size={24}
                    />
                  </p>
                </div>
                {/* <img
                  src="/stripe.png"
                  className="w-[480px] mt-[10px] mb-[20px] mx-auto"
                />

                <div className=" lg:px-[100px] w-full mx-auto mb-[20px] mt-3 p-3 border-dotted border-2 border-text-primary border-[#00F4BA]">
                  <CardElement options={CARD_ELEMENT_OPTIONS} />
                </div> */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 text-textColor px-8 py-6">
                  <div className="flex flex-col gap-3 items-center justify-between text-center bg-componentBg rounded-xl p-9">
                    <div className="flex flex-col gap-4">
                      <h2 className="text-3xl font-bold text-primary">
                        Pro Monthly
                      </h2>
                      <div className="text-lg">
                        <p>Unlimited Outputs</p>
                        <p>Text-to-Image with DALL-E</p>
                        <p>Remove All Ads</p>
                        <p>GPT-4 Access</p>
                        <p>Pro customer support</p>
                        <p>Free prompt pack</p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <span className="text-lg flex items-end">
                        $<span className="text-5xl">9.97</span>/month
                      </span>
                      <button className="bg-brightTeal text-lg text-dark px-3 py-2 rounded-lg font-[500]"  onClick={() => NewcreateSubscription('month')}>
                        Upgrade Now
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col gap-3 items-center text-center bg-componentBg rounded-xl p-9">
                    <div className="flex flex-col gap-4">
                      <h2 className="text-3xl font-bold text-primary">
                        Pro Yearly
                      </h2>
                      <div className="text-lg">
                        <p>Best Price</p>
                        <p>2 Months Free</p>
                        <p>Unlimited Outputs</p>
                        <p>Text-to-Image with DALL-E</p>
                        <p>Remove All Ads</p>
                        <p>GPT4 Access</p>
                        <p>Pro customer support</p>
                        <p>Free prompt pack</p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-4 justify-center items-center">
                      <span className="text-lg flex items-end">
                        $<span className="text-5xl">98.97</span>/year
                      </span>
                      <button className="bg-brightTeal text-lg text-dark px-3 py-2 rounded-lg font-[500]"  onClick={() => NewcreateSubscription('year')}>
                        Upgrade Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
