import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { login, getMe } from "../features/auth/authSlice";
import { getUserHistory } from "../features/chats/chatSlice";
import {
  getSystemPrompts,
  getUserPrompts,
} from "../features/prompts/promptSlice";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { confirmPassword, password } = formData;

  const { loginInfo, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!token) {
      navigate("/forget-password");
    }

    if (isError) {
      toast.error("Invalid credentials. Please try again!", {
        closeOnClick: true,
        autoClose: 2000,
      });
    }

    if (isSuccess || loginInfo?.token) {
      dispatch(getMe(loginInfo.token));
      dispatch(getUserHistory(loginInfo.token));
      dispatch(getSystemPrompts(loginInfo.token));
      dispatch(getUserPrompts(loginInfo.token));
      navigate("/dashboard");
    }
  }, [isError, isSuccess, loginInfo, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match", {
        closeOnClick: true,
        autoClose: 2000,
      });
      return;
    }

    const userData = {
      password,
    };

    try {
      setIsSubmitting(true);
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/auth/resetpassword/${token}`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;

      setIsSubmitting(false);
      toast.success(data.message, {
        closeOnClick: true,
        autoClose: 2000,
      });
      navigate("/");
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      const message = error.response?.data?.error ?? "An error occurred";
      toast.error(message, {
        closeOnClick: true,
        autoClose: 2000,
      });
    }
  };

  return (
    <section className="bg-dark flex flex-col justify-center items-center h-screen" style={{
      backgroundImage: 'url(/assets/imgs/authBack.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <div className="flex flex-col justify-center items-center">
      <h1 className="text-3xl md:text-5xl font-semibold text-white text-center">
          Welcome to{" "}
          <span
            className="border-s-4 border-primary"
            style={{
              background:
                "linear-gradient(90deg, rgba(32, 253, 201, 0.50) 0%, rgba(32, 253, 201, 0.00) 100%)",
            }}
          >
            ChadGPT
          </span>
        </h1>

        <div className="mt-14">
          <form
            className="flex flex-col justify-center items-center gap-5 w-full"
            onSubmit={onSubmit}
          >
            {/* Password Input */}
            <div className="input-group relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={onChange}
                className="px-3 py-4 w-96 focus:outline-primary"
                autoComplete="password"
                required
              />
              <label htmlFor="password">Password</label>
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showPassword ? 'Hide' : 'Show'}
              </button>
            </div>

            {/* Confirm Password Input */}
            <div className="input-group relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={onChange}
                className="px-3 py-4 w-96 focus:outline-primary"
                autoComplete="confirmPassword"
                required
              />
              <label htmlFor="confirmPassword">Confirm Password</label>
              <button
                type="button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
              >
                {showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>

            {/* Submit Button */}
            <button
              disabled={isSubmitting}
              type="submit"
              style={{
                backgroundColor: isSubmitting ? 'rgba(32, 253, 201, 0.5)' : '#20FDC9',
              }}
              className="w-96 p-4 text-dark rounded-md font-semibold text-lg"
            >
              {isLoading ? "Loading..." : "Update Password"}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
